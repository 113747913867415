import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./back-button.scss";

export default class BackButton extends Component {

  render () {
    return (
      <Link to="/" className="back-button">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.625 12.75L6.375 8.5L10.625 4.25" stroke="#C3C7CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Back to CalebPlain.com
      </Link>
    )
  }
}