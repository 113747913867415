import React, { Component } from 'react';
import BackButton from "../components/case-studies/back-button/back-button.js";
import "../components/case-studies/case-studies.scss";
import "../components/project-browser/project.scss";

export default class SPX extends Component {
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("spx");
  }
  render () {
    return (
      <div className="project surface">
        <div className="CaseStudy section">
          <BackButton />
          <h1>Smaato SPX Platform</h1>
          <h2>
          Smaato had a dated advertising platform and needed to improve their publisher experience. I updated the workflows, created a fresh visual design, and made the layout responsive.
          </h2>
          <div className="CaseStudy_specimen" style={{paddingTop: "74.72894078%"}}>
            <img className="CaseStudy_specimen__image" src="/projects/older/spx-4.png" alt="Smaato SPX" />
          </div>
        </div>
      </div>
    )
  }
}