import React from 'react';
import 'react-html5video/dist/styles.css';
import './Prototype.scss';

class CaseStudyPrototype extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">4</span>
          Prototyping for User Testing
        </h3>
        <p>
          We now had a clear vision of the app and how it would function. <strong>While tempting, I knew that starting to build at this point could be problematic.</strong> Everything was outlined, but nothing was tested. We needed to gather feedback from potential users. We needed candid feedback on usability, enjoyment, and frustration.
        </p>
        <p>
          To share our vision, I built a semi-functional prototype with <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> &amp; <a href="https://sass-lang.com" target="_blank" rel="noopener noreferrer">SASS</a>. I also added a visual design during this step to make the experience more palatable. The prototype demonstrated how the app would function once built, and enabled us to test the design with potential users.
        </p>
        <div className="CaseStudy_Prototype">
          <div className="CaseStudy_Prototype_videoContainer">
            <div className="CaseStudy_Prototype_videoContainer_video">
              <div className="video"><iframe title="Prototype" src="https://player.vimeo.com/video/341316397?title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="iframe"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CaseStudyPrototype;
