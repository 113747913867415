import React from 'react';
import './Personas.scss';

class Personas extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">3</span>Who is the User?
        </h3>
        <p>
          Based on our competitive research and analytics of Farmer’s current solutions, it was obvious that our potential user base was diverse. Their device, age, vehicles, driving history, marital status, and information absorption would vary drastically. To help us understand this dynamic user base, <strong>we created user persona profiles that represented segments of the market.</strong> These profiles were like baseball cards, with all the relevant stats listed. We used these profiles to test our designs and verify our approach, accounting for all user segments.
        </p>
        <div className="Quote__personas">
          <ul className="Quote__personas__profilesList">
            {Object.keys(this.props.state.personas).map(key =>
              <li className="Quote__personas__profilesList__persona" key={key}>
                <div className="Quote__personas__profilesList__persona__profile">
                  <img className="Quote__personas__profilesList__persona__profile__image" src={"/projects/quote/persona-"+this.props.state.personas[key].id+".png"} alt="{this.props.state.personas[key].name}" />
                  <span className="Quote__personas__profilesList__persona__profile__container">
                    <span className="Quote__personas__profilesList__persona__profile__name">
                      {this.props.state.personas[key].name}
                    </span>
                    <span className="Quote__personas__profilesList__persona__profile__location">
                      {this.props.state.personas[key].location}, Born {this.props.state.personas[key].dob}
                    </span>
                  </span>
                </div>
                <ul className="Quote__personas__profilesList__persona__detailsList">
                  {Object.keys(this.props.state.personas[key].items).map(item =>
                    <li className="Quote__personas__profilesList__persona__detailsList__item" key={item}>
                      <span className="Quote__personas__profilesList__persona__detailsList__item__wrapper">
                        <img className={"Quote__personas__profilesList__persona__detailsList__item__wrapper__image Quote__personas__profilesList__persona__detailsList__item__wrapper__image--"+ (this.props.state.personas[key].items[item].circle ? 'circle' : 'square')}

                        src={"/projects/quote/persona-item-"+this.props.state.personas[key].items[item].id+".png"} alt={ this.props.state.personas[key].items[item].itemTitle } />
                        <span className="Quote__personas__profilesList__persona__detailsList__item__wrapper__container">
                          <span className="Quote__personas__profilesList__persona__detailsList__item__wrapper__container__title">
                            { this.props.state.personas[key].items[item].itemLabel }
                          </span>
                          <span className="Quote__personas__profilesList__persona__detailsList__item__wrapper__container__item">
                            { this.props.state.personas[key].items[item].itemTitle }
                          </span>
                          <span className="Quote__personas__profilesList__persona__detailsList__item__wrapper__container__itemDetail">
                            { this.props.state.personas[key].items[item].itemDetails }
                          </span>
                        </span>
                      </span>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </>
    )
  }
}

export default Personas;
