import firebase from 'firebase';

const config = {
  // apiKey: "AIzaSyDydVt_jUdrRq9c6pA7ich8_nEEgDBPxCs",
  // authDomain: "portfolio-9ae6e.firebaseapp.com",
  // databaseURL: "https://portfolio-2031.firebaseio.com",

  apiKey: "AIzaSyBlX3-nEGzJh06cCQbam_uBN2zAhxnvYFc",
  authDomain: "testportfolio-874fe.firebaseapp.com",
  databaseURL: "https://testportfolio-874fe-default-rtdb.firebaseio.com/",
  projectId: "portfolio-9ae6e",
  messagingSenderId: "446996039370",
  appId: "1:446996039370:web:f13106e7004aa1337e9633"
}

firebase.initializeApp(config);

export const fb = firebase;
export const ref = firebase.database().ref();
export const firebaseAuth = firebase.auth;