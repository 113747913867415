import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Magnifier from "react-magnifier";
import Personas from './Personas';
import Prototypes from './Prototypes';
import BackButton from "../../components/case-studies/back-button/back-button.js";
import ProjectBrowser from '../../components/project-browser/project-browser';
//import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import "../../components/project-browser/project.scss";
import '../../components/case-studies/case-studies.scss';
import './_Index.scss';

class Quote extends React.Component {

  state = {
    wireframesMag: false,
    personas: {
      1: {
        id: "kierra",
        name: "Kierra Tatum",
        location: "Los Angeles, CA 90068",
        dob: "09/19/1996",
        items: {
          device: {
            id: "iphone-7-gold",
            itemLabel: "Device",
            itemTitle: "iPhone 7s",
            itemDetails: "Apple"
          },
          vehicle: {
            id: "2015-toyota-prius",
            itemLabel: "Finances",
            itemTitle: "2015 Toyota Prius",
            itemTitleShort: "Toyota Prius",
            itemDetails: "Three 4D Sedan"
          },
          accident: {
            id: "collision",
            itemLabel: "Accident",
            itemTitle: "Collision",
            itemDetails: "03/21/2016"
          },
          citation: {
            id: "citation",
            itemLabel: "Citation",
            itemTitle: "Speeding",
            itemDetails: "04/09/2016"
          }
        }
      },
      2: {
        id: "jessica",
        name: "Jessica Idella",
        location: "Philadelphia, PA 19125",
        dob: "01/29/1959",
        items: {
          device: {
            id: "pixel-3a-gold",
            itemLabel: "Device",
            itemTitle: "Pixel 3a",
            itemDetails: "Google"
          },
          spouse: {
            id: "ralph-sybil",
            itemLabel: "Married",
            itemTitle: "Ralph Sybil",
            itemDetails: "Born 12/16/1958",
            circle: true
          },
          vehicle: {
            id: "1980-jeep-cherokee",
            itemLabel: "OWNS",
            itemTitle: "1980 Jeep Cherokee",
            itemTitleShort: "Jeep Cherokee",
            itemDetails: "Chief 4D SUV6"
          },
          citation: {
            id: "citation",
            itemLabel: "Citation",
            itemTitle: "Distracted",
            itemDetails: "08/06/2015"
          }
        }
      },
      3: {
        id: "david",
        name: "David Friedman",
        location: "Phoenix, AZ 34323",
        dob: "04/02/1942",
        items: {
          device: {
            id: "iphone-8-black",
            itemLabel: "Device",
            itemTitle: "iPhone 8",
            itemDetails: "Apple"
          },
          spouse: {
            id: "susan-friedman",
            itemLabel: "Married",
            itemTitle: "Susan Friedman",
            itemDetails: "Born 02/20/45",
            circle: true
          },
          vehicle1: {
            id: "2017-bmw-x4",
            itemLabel: "Leases",
            itemTitle: "2017 BMW X4",
            itemTitleShort: "BMW X4",
            itemDetails: "AWD 4D SUV"
          },
          vehicle2: {
            id: "2012-honda-accord",
            itemLabel: "FINANCES",
            itemTitle: "2012 Honda Accord",
            itemTitleShort: "Honda Accord",
            itemDetails: "EX-L 4D Sedan"
          }
        }
      }
    }
  }

  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("quote");
  }

  render() {
    return (
      <div className="project">
        <div className="Quote CaseStudy section">
          <BackButton />
          <h1 className="themed">Farmers Insurance</h1>
          <h2 className="themed">
            Farmers wanted to launch an ad campaign to offer insurance quotes to mobile users through a modern web experience. The goal was simple: get users an accurate quote quickly.
          </h2>
          {/* <span className="Quote__main themed">
            <span className="Quote__main__device">
              <Video className="farmers" autoPlay loop muted playsInline="playsinline"
                  controls={[]}>
                <source src="/projects/quote/main.mp4" type="video/mp4"/>
                <source src="/projects/quote/main.webm" type="video/webm"/>
                <source src="/projects/quote/main.ogv" type="video/ogg"/>
              </Video>
            </span>
          </span> */}
          <div className="Quote__specimens">
            <div className="Quote__specimens__specimen">
              <img src="/projects/quote/specimen-1.png" alt="temp" />
            </div>
            <div className="Quote__specimens__specimen">
              <img src="projects/quote/specimen-2.png" alt="temp" />
            </div>
            <div className="Quote__specimens__specimen">
              <img src="projects/quote/specimen-3.png" alt="temp" />
            </div>
          </div>
          <h4>Designing a Painless Quote Experience for Mobile Users:</h4>
          {/* <ul className="CaseStudy_cloud themed">
            <li className="label">Methods Utilized:</li>
            <li>Competitive Research</li>
            <li>Screen Recordings</li>
            <li>Workflow Mapping</li>
            <li>Wireframes</li>
            <li>Diary Studies</li>
            <li>User Personas</li>
            <li>Heatmaps</li>
            <li>A/B Testing</li>
            <li>User Session Playback</li>
            <li>Focus Groups</li>
            <li>Journey Maps</li>
            <li>Usability Testing</li>
            <li>Prototypes</li>
          </ul> */}
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">1</span>Competitive Research
          </h3>
          <p>
            I started the project by researching what users were currently facing, learning their feelings about getting a quote in the current marketplace.
          </p>
          <p>
            I identified what competitors offered the most successful workflows, and then performed usability testing sessions on these competitor’s sites. While keeping a basic journal, we asked a sampling of potential users to get an insurance quote, documenting their experiences, in addition to recording their screens. This data helped us identify what was working for users, what could be improved, the average time spent on each section of the quote process, and who offered the best overall experience.
          </p>
          <p>
          <strong>While I could have started designing immediately based on my experience and expectations for users, it would have been a mistake.</strong> Real users provide invaluable insights, and designing with an understanding of the usability of the current market helps guide the process and keeps the focus on the user.
          </p>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">2</span>Mapping It Out
          </h3>
          <p>
            Now that we had a general idea of who our users were and what could be improved within the existing market, it was time to establish a workflow. What information would appear on each screen and what information did we need to gather from the user? How could we group this information in a thoughtful way? We knew from our research that long forms with countless fields overwhelmed users, but we also knew that endless steps frustrated users and caused them to give up. We needed to find a happy medium.
          </p>
          <p>
            I started by creating rough wireframes in Sketch (below) to establish an overall workflow and detail how information could be organized into screens. The goal was to create screens that broke up the long forms into sections, minimizing or eliminating scrolling, but also attempting to keep the total number of screens low. I did this by creating a series of thoughtful layouts that didn’t waste a user’s screen real estate, but also didn’t space things out needlessly.
          </p>
          <div className="CaseStudy_exhibitHeader">
            Examine Early Wireframes:
          </div>
          <div className="CaseStudy_magnifierContainer">
            <img className="noMagnifierOpt" src="/projects/quote/wireframes.png" alt="Wireframes" />
            <Magnifier className="magnifierOpt" src={"/projects/quote/wireframes.png"} mgWidth={500} mgShape={"square"} zoomFactor={.4} mgHeight={500} width="100%" />
          </div>

          <Personas state={this.state} />
          <Prototypes state={this.state} />
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">5</span>Production Build
          </h3>
          <p>
            With our prototype tested, we were excited to start building a production version with our engineering team. We reused most of the HTML and CSS from the prototype, and worked closely with engineering to guarantee everything was functioning as intended.
          </p>
          <p>
            The number one user experience issue one can face is an error or disruptive bug. <strong>Staying involved in the engineering and QA phases of the project helps guarantee the quality intended in the design translates into the production build.</strong>
          </p>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">6</span>Limited Launch and Further Testing
          </h3>
          <p>
            With a production version completed, we were ready for real users. In the insurance industry, the quote form is critical and <strong>mistakes can disrupt business.</strong> To avoid this, we launched the experience with a limited number of randomly selected users. We gathered analytics on how the new experience was working when compared to previous campaigns. We tracked and compared session lengths, conversion rates, drop-offs, session playbacks, heatmaps, and analyzed written user feedback gathered from support and chat sessions.
          </p>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">7</span>We Had a Winner
          </h3>
          <p>
            With nearly every user segment, we saw improvement. Users were cruising through the form, and the conversion rate of users reaching the final quote was notably higher than previous campaigns.
          </p>
          <div className="Quote__imageFull">
            <img className="Quote__imageFull__image" src="/projects/quote/wild.jpg" alt="In the wild" />
          </div>

        </div>
      </div>
    )
  }
}

export default Quote;
