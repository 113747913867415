import React, { Component } from 'react';
import BackButton from "../components/case-studies/back-button/back-button.js";
import "../components/case-studies/case-studies.scss";
import "../components/project-browser/project.scss";

export default class Settle extends Component {
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("settle");
  }
  render () {
    return (
      <div className="project surface">
        <div className="CaseStudy section">
          <BackButton />
          <h1>Settle Platform</h1>
          <h2>
            Settle is the big brother to the Cryptoberg project, which was limited to the broker-dealer market. The platform will allow all types of investors to negotiate trades, monitor markets, manage their crypto assets, and install third party apps from partners.
          </h2>
          <div className="CaseStudy_specimen" style={{paddingTop: "104.10464592%" }}>
            <img className="CaseStudy_specimen__image" src="/projects/older/settle-full.png" alt="Settle" />
          </div>
        </div>
      </div>
    )
  }
}