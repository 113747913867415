import React from 'react';
import { Link } from 'react-router-dom';
import Plx from 'react-plx';
import BackButton from "../../components/case-studies/back-button/back-button.js";
import { DefaultPlayer as Video } from 'react-html5video';
import '../../components/case-studies/case-studies.scss';
import './_Index.scss';

const parallaxFlames = [
  {
    start: "self",
    end: "100%",
    startOffset: "10%",
    endOffset: "0",
    properties: [
      // {
      //   startValue: 100,
      //   endValue: 1,
      //   property: 'translateY',
      // },
    ],
  },
];

class Refunds extends React.Component {

  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("refunds");
  }

  render() {
    return (
      <div className="project refunds">
        <div className="Quote CaseStudy section">
          <BackButton />
          <h1>Boulevard Refunds</h1>
          <h2>Boulevard is a business management platform for appointment based businesses. In 2019, processing a refund was one of the most common support requests from customers.  Refunds were confusing and tedious, and people were frustrated. We needed to create a refund experience that felt delightful and automated.</h2>
          <div className="refunds_specimen">
            <img src="/projects/refunds/refunds-4.png" alt="Boulevard Refunds" />
          </div>
          <h4>Designing a refund process with little margin for error</h4>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">1</span>Remembering Boulevard's Users</h3>
          <p>It's important to pause for a second and understand who uses Boulevard's platform. While Boulevard creates enterprise software, many of our users have never used such a thing. My favorite piece of feedback from customers is that "Boulevard feels more like the apps that I use on my phone every day." This is the platform design goal, and the refunds experience needed to support this vision.</p>
          <div className="refunds_image">
            <img src="/projects/refunds/user.jpg" alt="Boulevard User" />
          </div>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">2</span>Understanding the Problem
          </h3>
          <p>
            Refunds are consequential. There are financial repercussions for the business, staff, and customers. Additionally, the staff is normally performing a refund with an upset customer waiting. This meant that the user would not be focused on the software, and would instead be entirely focused on providing the customer excellent service. The software just needed to work without much thought.
          </p>
          <p>
            To learn more about what real world refunds looked like in our software, I collaborated with product management to research customer challenges. We started by organizing and compiling support requests to identify trends, and then interviewed customers to understand their experience firsthand. We used Fullstory to analyze session playbacks and Looker to analyze refund transactions.
          </p>
          <p>
            <strong>We noticed a couple of interesting things:</strong>
            <ul className="four-across">
              <li>
                <div className="row">
                  <span className="letter">a</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-percent"><line x1="19" y1="5" x2="5" y2="19"></line><circle cx="6.5" cy="6.5" r="2.5"></circle><circle cx="17.5" cy="17.5" r="2.5"></circle></svg>
                  <span className="title">
                    Commission Adjustments
                  </span>
                </div>
                Refunds almost always end in commission adjustments. Staff members manually edit compensation data after a refund.
              </li>
              <li>
                <div className="row">
                  <span className="letter">b</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                  <span className="title">Manual Restocking</span>
                </div>Products were often restocked. If a product was unused, staff would likely manually add the product back to inventory.
              </li>
              <li>
                <div className="row">
                  <span className="letter">d</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                  <span className="title">Split Payments</span>
                </div>Split payments were frequent and complicated to refund properly. They nearly always generated a support request.
              </li>
              <li>
                <div className="row">
                  <span className="letter">c</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-frown"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                  <span className="title">Emotions Running High</span>
                </div>People were often upset. It was common that someone was unhappy with their haircut or color, and it could be incredibly emotional.
              </li>
            </ul>
          </p>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">3</span>Finding a Solution
          </h3>
          <p>
            The initial idea was to improve the existing workflow and address the points of friction. Basically, a user would start by selecting an amount to refund, and then we would add some followup options to address complications like commission. After exploring this path, I realized that this was not well thought out. I was simply bolting complications onto an already broken workflow.
          </p>
          <div className="refunds_burnit_container smaller">
            <div className="fire">
              <img src="/projects/refunds/fire.gif" alt="On Fire" />
            </div>
            <div className="screenshot">
              <img src="/projects/refunds/burn-it.png" alt="Burn It" />
            </div>
          </div>
          <p>
            With this in mind, I created basic user stories for refunds and started to think about the process from the ground up. This was illuminating. As it turns out, refunds aren't that complicated, and they are fundamentally just reverse checkout orders.
          </p>
          <p>
            </p>
          <p>
            Refunds should start with selecting line items, which turn into reverse payments. No math, no calculators. It's simple.
          </p>
          <h3 className="CaseStudy_stepTitle" style={{clear: "both"}}>
            <span className="CaseStudy_stepTitle_number">4</span>Handling the Complexities
          </h3>
          <p>
            Now that we had a new foundational workflow, it was important to address the complexities identified earlier.
          </p>
          <p>
            <ul className="three-across">
              <li>
                <div className="row">
                  <span className="title">Commission and Restocking</span>
                </div>
                <span>To address commission adjustments and restocking, I added select controls beside every line item. This allowed the staff member to handle these situations at the time of the refund.</span>
                <Video style={{width:194, height: 284}} autoPlay muted loop playsInline>
                    <source src="/projects/refunds/commission.mp4" type="video/mp4"/>
                </Video>
              </li>
              <li>
                <div className="row">
                  <span className="title">Partial Refunds</span>
                </div>
                <span>It's common that salons will offer only a partial refund to a customer who is upset about a service. I built in a way to select from common percentages so the front desk could avoid math.</span>
                <Video style={{width:250, height: 284}} autoPlay muted loop playsInline>
                    <source src="/projects/refunds/partial-refund.mp4" type="video/mp4"/>
                </Video>
              </li>
              <li>
                <div className="row">
                  <span className="title">Distributing Payments</span>
                </div>
                <span>If someone paid with more than one payment method, say cash and a check, they may prefer to get it back as cash. I built in a way to edit the distribution to handle these situations.</span>
                <Video style={{width:230, height: 284}} autoPlay muted loop playsInline>
                    <source src="/projects/refunds/distribute.mp4" type="video/mp4"/>
                </Video>
              </li>
            </ul>
          </p>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">5</span>Prototypes and Testing
          </h3>
          <p>
            At this point, I was feeling confident in my solution, but I needed to validate the decisions I made. To do this, I created a prototype, set up a series of refund scenarios, and asked usability testing participants to follow prompts and accomplish tasks.
          </p>
          <p>
            I used React for the prototype and constructed it with the React version of our component library. This allowed me to create realistic workflows that allowed users to deviate from the happy path.
          </p>
          <p>
            Overall, the solution performed well. We made a few adjustments to some of the UX copy, simplified the payment distribution options, and increased the visibility of past refunds.
          </p>
          <div className="refunds_image">
            <img src="/projects/refunds/user-testing.jpg" alt="User Testing Session" />
          </div>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">6</span>Rollout
          </h3>
          <p>
            With the solution tested and refined, we began trialing it with customers. The feedback was stellar. People loved it, and the support requests involving refunds dropped dramatically as we continued the roll out.
          </p>
          <p>
            <strong>Here is a demo of an actual refund with the redesigned workflow.</strong><br/>
            For context, imagine that a client named Frederick bought a conditioner, but changed his mind and chose to return it. Let's assume that we would like to leave the sales commission with the seller (Jorge), and that the product is unused. Let's also imagine that Frederick would like $20 back as cash, and for us to place the rest on his Visa credit card.
          </p>
          <div className="refunds_demo">
            <Video  onEnded={(v)=>{v.target.play();v.target.pause()}} muted playsInline controls={['PlayPause', 'Seek']} onLoadStart={(v)=>{v.target.play();v.target.pause()}} poster="/projects/refunds/poster.jpg">
              <source src="/projects/refunds/demo.mp4" type="video/mp4"/>
            </Video>
          </div>
        </div>
      </div>
    )
  }
}

export default Refunds;
