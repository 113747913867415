import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Plx from 'react-plx';
import "./projects-grid.scss";

const parallaxProjectGrid = [
  // {
  //   start: "30vh",
  //   end: "60vh",
  //   properties: [
  //     {
  //       startValue: 1,
  //       endValue: 1.2,
  //       property: 'scale',
  //     },
  //   ],
  // },
];

export default class ProjectsGrid extends Component {

  render() {
    return (
      <div>
        <ul className="projects-grid">
          <li>
            <Link to="/checkout" className="project-grid-card">
              <span className="project-grid-flag">
                {!this.props.authed ?
                  <><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.33333 6.91667V4.58333C4.33333 3.80978 4.64062 3.06792 5.18761 2.52094C5.73459 1.97396 6.47645 1.66667 7.25 1.66667C8.02355 1.66667 8.76541 1.97396 9.31239 2.52094C9.85938 3.06792 10.1667 3.80978 10.1667 4.58333V6.91667M3.16667 6.91667H11.3333C11.9777 6.91667 12.5 7.439 12.5 8.08333V12.1667C12.5 12.811 11.9777 13.3333 11.3333 13.3333H3.16667C2.52233 13.3333 2 12.811 2 12.1667V8.08333C2 7.439 2.52233 6.91667 3.16667 6.91667Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>Password Required</>
                  :
                  <><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path d="M7.25 1.66667L9.0525 5.31833L13.0833 5.9075L10.1667 8.74833L10.855 12.7617L7.25 10.8658L3.645 12.7617L4.33333 8.74833L1.41666 5.9075L5.4475 5.31833L7.25 1.66667Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)"/>
                    </clipPath>
                    </defs>
                  </svg>Featured Project</>
                }

              </span>
              <div className="project-grid-image">
                {this.props.authed && this.props.privateContent ?
                  <img src={"/projects/"+this.props.privateContent.projects["checkout"].secretID+"/"+this.props.privateContent.projects["checkout"].secretID+".png"} alt={this.props.privateContent ? this.props.privateContent.projects["checkout"].shortName : "Checkout"}/>
                  :
                  <img src="/projects/secret.png" alt="Checkout" />
                }
              </div>
              <div className="project-grid-title">
                {this.props.privateContent ? this.props.privateContent.projects["checkout"].shortName : "Checkout"}
              </div>
              <div className="project-grid-subtitle">iOS, iPad OS, Android</div>
            </Link>
          </li>
          <li>
            <Link to="/pos" className="project-grid-card">
              <span className="project-grid-flag">
                {!this.props.authed ?
                  <><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.33333 6.91667V4.58333C4.33333 3.80978 4.64062 3.06792 5.18761 2.52094C5.73459 1.97396 6.47645 1.66667 7.25 1.66667C8.02355 1.66667 8.76541 1.97396 9.31239 2.52094C9.85938 3.06792 10.1667 3.80978 10.1667 4.58333V6.91667M3.16667 6.91667H11.3333C11.9777 6.91667 12.5 7.439 12.5 8.08333V12.1667C12.5 12.811 11.9777 13.3333 11.3333 13.3333H3.16667C2.52233 13.3333 2 12.811 2 12.1667V8.08333C2 7.439 2.52233 6.91667 3.16667 6.91667Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>Password Required</>
                  :
                  <><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path d="M7.25 1.66667L9.0525 5.31833L13.0833 5.9075L10.1667 8.74833L10.855 12.7617L7.25 10.8658L3.645 12.7617L4.33333 8.74833L1.41666 5.9075L5.4475 5.31833L7.25 1.66667Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)"/>
                    </clipPath>
                    </defs>
                  </svg>Featured Project</>
                }

              </span>
              <div className="project-grid-image">
                {this.props.authed && this.props.privateContent ?
                  <img src={"/projects/"+this.props.privateContent.projects["pos"].secretID+"/"+this.props.privateContent.projects["pos"].secretID+".png"} alt={this.props.privateContent ? this.props.privateContent.projects["pos"].shortName : "Point of Sale"}/>
                  :
                  <img src="/projects/secret.png" alt="Point of Sale" />
                }
              </div>
              <div className="project-grid-title">
                {this.props.privateContent ? this.props.privateContent.projects["pos"].shortName : "Point of Sale"}
              </div>
              <div className="project-grid-subtitle">iPad OS, Android</div>
            </Link>
          </li>
          <li>
            <Link to="/refunds" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <path d="M1.41666 2.25H4.91666C5.5355 2.25 6.129 2.49583 6.56658 2.93342C7.00416 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.06562 11.8408 6.73743 11.5126C6.40925 11.1844 5.96413 11 5.5 11H1.41666V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.0833 2.25H9.58333C8.96449 2.25 8.371 2.49583 7.93342 2.93342C7.49583 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.43437 11.8408 7.76256 11.5126C8.09075 11.1844 8.53587 11 9 11H13.0833V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
                Case Study
              </span>
              <div className="project-grid-image">
                <img src="/projects/older/refunds.png" alt="Refunds"/>
              </div>
              <div className="project-grid-title">Refunds</div>
              <div className="project-grid-subtitle">Platform Feature</div>
            </Link>
          </li>
          <li>
            <Link to="/cryptoberg" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <path d="M1.41666 2.25H4.91666C5.5355 2.25 6.129 2.49583 6.56658 2.93342C7.00416 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.06562 11.8408 6.73743 11.5126C6.40925 11.1844 5.96413 11 5.5 11H1.41666V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.0833 2.25H9.58333C8.96449 2.25 8.371 2.49583 7.93342 2.93342C7.49583 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.43437 11.8408 7.76256 11.5126C8.09075 11.1844 8.53587 11 9 11H13.0833V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
                Case Study
              </span>
              <div className="project-grid-image">
                <img src="/projects/cryptoberg/thumbnail-square.png" alt="Cryptoberg"/>
              </div>
              <div className="project-grid-title">Cryptoberg</div>
              <div className="project-grid-subtitle">Web App</div>
            </Link>
          </li>
          <li>
            <Link to="/quote" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <path d="M1.41666 2.25H4.91666C5.5355 2.25 6.129 2.49583 6.56658 2.93342C7.00416 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.06562 11.8408 6.73743 11.5126C6.40925 11.1844 5.96413 11 5.5 11H1.41666V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.0833 2.25H9.58333C8.96449 2.25 8.371 2.49583 7.93342 2.93342C7.49583 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.43437 11.8408 7.76256 11.5126C8.09075 11.1844 8.53587 11 9 11H13.0833V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
                Case Study
              </span>
              <div className="project-grid-image">
                <img src="/projects/quote/quote.png" alt="Insurance Quote"/>
              </div>
              <div className="project-grid-title">Insurance Quote</div>
              <div className="project-grid-subtitle">Mobile Web</div>
            </Link>
          </li>
          <li>
            <Link to="/smaato-ui" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0)">
                  <path d="M1.41666 2.25H4.91666C5.5355 2.25 6.129 2.49583 6.56658 2.93342C7.00416 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.06562 11.8408 6.73743 11.5126C6.40925 11.1844 5.96413 11 5.5 11H1.41666V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.0833 2.25H9.58333C8.96449 2.25 8.371 2.49583 7.93342 2.93342C7.49583 3.371 7.25 3.96449 7.25 4.58333V12.75C7.25 12.2859 7.43437 11.8408 7.76256 11.5126C8.09075 11.1844 8.53587 11 9 11H13.0833V2.25Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0">
                  <rect width="14" height="14" fill="white" transform="translate(0.25 0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
                Case Study
              </span>
              <div className="project-grid-image">
                <img src="/projects/smaato-ui-framework/box-new.png" alt="Smaato UI Framework"/>
              </div>
              <div className="project-grid-title">Smaato UI</div>
              <div className="project-grid-subtitle">UI Framework</div>
            </Link>
          </li>
          <li>
            <Link to="/settle" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.0833 1.75H2.91667C2.27233 1.75 1.75 2.27233 1.75 2.91667V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V2.91667C12.25 2.27233 11.7277 1.75 11.0833 1.75Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.95834 5.83333C5.44158 5.83333 5.83334 5.44158 5.83334 4.95833C5.83334 4.47508 5.44158 4.08333 4.95834 4.08333C4.47509 4.08333 4.08334 4.47508 4.08334 4.95833C4.08334 5.44158 4.47509 5.83333 4.95834 5.83333Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.25 8.75L9.33334 5.83333L2.91667 12.25" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Screenshot
              </span>
              <div className="project-grid-image">
                <img src="/projects/older/settle-square.png" alt="Settle"/>
              </div>
              <div className="project-grid-title">Settle</div>
              <div className="project-grid-subtitle">Web Platform</div>
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.0833 1.75H2.91667C2.27233 1.75 1.75 2.27233 1.75 2.91667V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V2.91667C12.25 2.27233 11.7277 1.75 11.0833 1.75Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.95834 5.83333C5.44158 5.83333 5.83334 5.44158 5.83334 4.95833C5.83334 4.47508 5.44158 4.08333 4.95834 4.08333C4.47509 4.08333 4.08334 4.47508 4.08334 4.95833C4.08334 5.44158 4.47509 5.83333 4.95834 5.83333Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.25 8.75L9.33334 5.83333L2.91667 12.25" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Screenshot
              </span>
              <div className="project-grid-image">
                <img src="/projects/older/dashboard-square.png" alt="Ad Dashboard"/>
              </div>
              <div className="project-grid-title">Ad Dashboard</div>
              <div className="project-grid-subtitle">Web App</div>
            </Link>
          </li>
          <li>
            <Link to="/spx" className="project-grid-card">
              <span className="project-grid-flag">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.0833 1.75H2.91667C2.27233 1.75 1.75 2.27233 1.75 2.91667V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V2.91667C12.25 2.27233 11.7277 1.75 11.0833 1.75Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.95834 5.83333C5.44158 5.83333 5.83334 5.44158 5.83334 4.95833C5.83334 4.47508 5.44158 4.08333 4.95834 4.08333C4.47509 4.08333 4.08334 4.47508 4.08334 4.95833C4.08334 5.44158 4.47509 5.83333 4.95834 5.83333Z" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.25 8.75L9.33334 5.83333L2.91667 12.25" stroke="#33343C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Screenshot
              </span>
              <div className="project-grid-image">
                <img src="/projects/older/spx-square.png" alt="Ad Server"/>
              </div>
              <div className="project-grid-title">Ad Server</div>
              <div className="project-grid-subtitle">Web Platform</div>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
