import React from 'react';
import './Workflow.scss';

class CaseStudyWorkflow extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">2</span>
          Creating a Workflow
        </h3>
        <p>Next we needed to define the process of how a user would accomplish the desired tasks. It was complex: there was a handoff from the dealer to customer, some tasks applied only to certain roles, and the workflow order could change or reset based on a single user decision.</p>
        <p>To bring some clarity, we translated the overall tasks into individual screens. We then organized the screens into logical order, and added arrows that identified the potential workflow paths. We color coded the illustration of the screens based on the roles, which made it clear if the screen applied to a specific role or if it was common between both roles.</p>
        <div className="CaseStudy_Workflow">
          <div className="CaseStudy_Workflow_row">
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBlueBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                  <img className="blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
                </div>
                <p className="title"><strong>Login</strong></p>
                <p>Authenticate with email and a supplied password.</p>
              </div>
              <span className="bottomSpace">
                <span className="bottomLine rightHalf"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowRight"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBlueBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                  <img className="blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
                </div>
                <p className="title"><strong>Contacts List</strong></p>
                <p>Display a list of your contacts and their online status.</p>
              </div>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowRight"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBlueBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                  <img className="blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
                </div>
                <p className="title"><strong>Chat</strong></p>
                <p>Send and receive messages with a selected contact</p>
              </div>
              <span className="bottomSpace">
                <span className="lineDown"></span>
                <span className="bottomLine leftHalf"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowLeft"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <span className="topSpace">
                <span className="horizontalLine"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="curveLeftUp"></span>
              </span>
              <span className="middleSpace">
                <span className="line"></span>
              </span>
              <span className="bottomSpace">
                <span className="line"></span>
              </span>
            </div>
          </div>
          <div className="CaseStudy_Workflow_row">
            <div className="CaseStudy_Workflow_cell content">
              <span className="bottomSpace">
                <span className="arrowDown"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer"></div>
            <div className="CaseStudy_Workflow_cell content"></div>
            <div className="CaseStudy_Workflow_cell spacer"></div>
            <div className="CaseStudy_Workflow_cell content"></div>
            <div className="CaseStudy_Workflow_cell spacer"></div>
            <div className="CaseStudy_Workflow_cell content"></div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="bottomSpace">
                <span className="line"></span>
              </span>
            </div>
          </div>
          <div className="CaseStudy_Workflow_row">
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                </div>
                <p className="title"><strong>Propose Trade</strong></p>
                <p>Enter a proposed swap of ERC-20 tokens between the selected customer and another.</p>
              </div>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowRight"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile blueBorder">
                <div className="personaIcons">
                  <img className="blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
                </div>
                <p className="title"><strong>Review Trade</strong></p>
                <p>Review proposed trade of ERC-20 tokens sent from Broker-Dealer.</p>
              </div>
              <span className="bottomSpace">
                <span className="arrowUp"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowRight"></span>
                <span className="curveDown"></span>
              </span>
              <span className="middleSpace">
                <span className="line"></span>
              </span>
              <span className="bottomSpace">
                <span className="line"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile blueBorder">
                <div className="personaIcons">
                  <img className="blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
                </div>
                <p className="title"><strong>Accept Trade</strong></p>
                <p>Accept the proposed trade and send it back to the broker.</p>
              </div>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowRight"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                </div>
                <p className="title"><strong>Finalize Trade</strong></p>
                <p>Digitally sign trade and send to blockchain for mining.</p>
              </div>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="curveUpRight"></span>
                <span className="line"></span>
              </span>
              <span className="middleSpace">
                <span className="line"></span>
              </span>
              <span className="bottomSpace">
                <span className="line"></span>
              </span>
            </div>
          </div>

          <div className="CaseStudy_Workflow_row">
            <div className="CaseStudy_Workflow_cell content">
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile empty">
                <span className="line"></span>
              </div>
              <span className="bottomSpace">
                <span className="line lineLeft"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowDownRight"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile blueBorder">
                <div className="personaIcons">
                  <img className="blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
                </div>
                <p className="title"><strong>Reject Trade</strong></p>
                <p>Reject the proposed trade.</p>
              </div>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowRight"></span>
                <span className="curveDown"></span>
              </span>
              <span className="middleSpace">
                <span className="line"></span>
              </span>
              <span className="bottomSpace">
                <span className="line"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                </div>
                <p className="title"><strong>Revise Trade</strong></p>
                <p>Enter a new proposed swap of a set number quantity of ERC-20 tokens.</p>
              </div>
              <span className="bottomSpace"></span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="curveDownRight"></span>
                <span className="line"></span>
              </span>
              <span className="middleSpace">
                <span className="line"></span>
                <span className="line-outer"></span>
              </span>
              <span className="bottomSpace">
                <span className="line"></span>
                <span className="line-outer"></span>
              </span>
            </div>
          </div>

          <div className="CaseStudy_Workflow_row">
            <div className="CaseStudy_Workflow_cell content">
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile empty">
                <span className="line"></span>
              </div>
              <span className="bottomSpace">
                <span className="curveUpLeft"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <div className="CaseStudy_Workflow_tile empty"></div>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile empty"></div>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="arrowDownRight"></span>
              </span>
              <span className="middleSpace"></span>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell content">
              <div className="CaseStudy_Workflow_tile orangeBorder">
                <div className="personaIcons">
                  <img className="orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker-Dealer" />
                </div>
                <p className="title"><strong>Close Trade</strong></p>
                <p>Cancel the proposed trade without revision.</p>
              </div>
              <span className="bottomSpace">
                <span className="bottomLine"></span>
              </span>
            </div>
            <div className="CaseStudy_Workflow_cell spacer">
              <span className="topSpace">
                <span className="curveUpRight"></span>
                <span className="line-outer"></span>
              </span>
              <span className="middleSpace">
                <span className="line-outer"></span>
              </span>
              <span className="bottomSpace">
                <span className="curveDownLeft"></span>
              </span>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default CaseStudyWorkflow;
