import React from 'react';
import './Personas.scss';

class CaseStudyPersonas extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">1</span>
          Identifying User Roles
        </h3>
        <p>We started the project by gathering requirements and researching potential users. We identified two main roles and described what each user would need to accomplish (i.e. user stories).</p>
        <div className="CaseStudy_personas">
          <div className="CaseStudy_personas_persona">
            <img className="CaseStudy_personas_persona_avatar orange" src="/projects/cryptoberg/persona-broker-dealer.png" alt="Broker Dealer" />
            <div className="CaseStudy_personas_persona_userStories">
              <span className="CaseStudy_personas_persona_userStories_userStory">As a <strong>Broker-Dealer</strong>, I would like to:</span>
              <ol className="CaseStudy_personas_persona_userStories_stories">
                <li>
                  <span className="CaseStudy_personas_persona_userStories_stories_number">1</span>
                  <span className="CaseStudy_personas_persona_userStories_stories_story">Chat with my customers</span></li>
                <li>
                  <span className="CaseStudy_personas_persona_userStories_stories_number">2</span>
                  <span className="CaseStudy_personas_persona_userStories_stories_story">Propose trades between customers</span>
                </li>
                <li>
                  <span className="CaseStudy_personas_persona_userStories_stories_number">3</span>
                  <span className="CaseStudy_personas_persona_userStories_stories_story">Execute trades on the blockchain</span>
                </li>
              </ol>
            </div>
          </div>
          <div className="CaseStudy_personas_persona">
            <img className="CaseStudy_personas_persona_avatar blue" src="/projects/cryptoberg/persona-customer.png" alt="Customer" />
            <div className="CaseStudy_personas_persona_userStories">
              <span className="CaseStudy_personas_persona_userStories_userStory">As a <strong>Customer</strong>, I would like to:</span>
              <ol className="CaseStudy_personas_persona_userStories_stories">
                <li>
                  <span className="CaseStudy_personas_persona_userStories_stories_number">1</span>
                  <span className="CaseStudy_personas_persona_userStories_stories_story">Chat with my broker</span>
                </li>
                <li>
                  <span className="CaseStudy_personas_persona_userStories_stories_number">2</span>
                  <span className="CaseStudy_personas_persona_userStories_stories_story">Review proposed trades</span>
                </li>
                <li><span className="CaseStudy_personas_persona_userStories_stories_number">3</span>
                  <span className="CaseStudy_personas_persona_userStories_stories_story">Accept or reject trades from my broker</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default CaseStudyPersonas;
