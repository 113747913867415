import React from 'react';
import './UsabilityTesting.scss';

class CaseStudyUsabilityTesting extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">5</span>
          Usability Testing Sessions
        </h3>
        <p>
          We conducted usability testing sessions via screen sharing video calls. We presented a list of tasks and asked each user to choose one, and then attempt to accomplish that task with the prototype.
        </p>
        <p>
          Feedback items from the sessions were documented and grouped by similarity. The larger groups were where we needed to focus most of our attention, and most of the critical feedback was for the broker-dealer role. Broker-dealers found the trading interface too basic, and were hoping to control broker fees. Additionally, broker-dealers wanted prices listed in a specific way to align with their record keeping practices. <strong>These are excellent examples of insights that can only be gleaned from real users and are truly invaluable</strong>.
        </p>
        <p>
          We revised the prototype accordingly based on the feedback, conducted follow up testing sessions, and were happy with the results. <strong>It was finally time to build.</strong>
        </p>
        <div className="CaseStudy__UsabilityTesting">
          <img className="CaseStudy__UsabilityTesting__image" src="/projects/cryptoberg/video-call.jpg" alt="Video Call" />
        </div>
      </>
    )
  }
}

export default CaseStudyUsabilityTesting ;
