import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Plx from 'react-plx';
import "./project-browser.scss";

const parallaxProjectBrowser = [
  {
    start: "0vh",
    end: "10vh",
    properties: [
      {
        startValue: 0,
        endValue: -100,
        units: "px",
        property: 'translateY',
      }
    ],
  },
  {
    start: "0vh",
    end: "10vh",
    properties: [
      {
        startValue: 0,
        endValue: 10,
        unit: "px",
        property: 'blur',
      },
    ],
  },
];

export default class ProjectBrowser extends Component {
  state = {
    selected: null,
  }
  projects = {
    "checkout": {
      title: "Checkout",
      subtitle: "iOS, iPad OS, Android",
      imageURL: "/projects/secret.png",
      private: true
    },
    "pos": {
      title: "Point of Sale",
      subtitle: "iPad OS",
      imageURL: "/projects/secret.png",
      private: true
    },
    "refunds": {
      title: "Refunds",
      subtitle: "Web App",
      imageURL: "/projects/older/refunds.png"
    },
    "cryptoberg": {
      title: "Cryptoberg",
      subtitle: "Web App",
      imageURL: "/projects/cryptoberg/thumbnail-square.png"
    },
    "quote": {
      title: "Insurance Quote",
      subtitle: "Mobile Web",
      imageURL: "/projects/quote/quote.png"
    },
    // "blvd-ui": {
    //   title: "BLVD UI",
    //   subtitle: "Design System",
    //   imageURL: "test.png"
    // },
    "smaato-ui": {
      title: "Smaato UI",
      subtitle: "UI Framework",
      imageURL: "/projects/smaato-ui-framework/box-new.png"
    },
    "settle": {
      title: "Settle",
      subtitle: "Web App",
      imageURL: "/projects/older/settle-square.png"
    },
    "spx": {
      title: "Smaato SPX",
      subtitle: "Web App",
      imageURL: "/projects/older/spx-square.png"
    },
    "dashboard": {
      title: "Ad Dashboard",
      subtitle: "Web App",
      imageURL: "/projects/older/dashboard-square.png"
    },


  }
  render() {
    return (
      <div className="project-browser-wrapper">
        <Plx parallaxData={parallaxProjectBrowser} className="scrolltrack">
          <ul className="project-browser">
            {Object.keys(this.projects).map(id => {
              const project = this.projects[id];
              if (project.private && this.props.authed && this.props.privateContent) {
                project.imageURL = "/projects/"+this.props.privateContent.projects[id].secretID+"/"+this.props.privateContent.projects[id].secretID+".png";
              }
              return(
                <li key={id} className={this.props.currentProject === id && "selected"}>
                  <Link to={"/"+id} className="project-browser-card">
                    <div className={(project.private && !this.props.authed && "private") + " project-browser-image"}>
                      <img src={project.imageURL} alt={project.title} />
                    </div>
                    <div className="project-browser-title">{project.title}</div>
                    <div className="project-browser-subtitle">{project.subtitle}</div>
                  </Link>
                </li>
              )
            })}
          </ul>
        </Plx>
      </div>
    );
  }
}
