import React, { Component } from 'react';
import BackButton from "../components/case-studies/back-button/back-button.js";
import "../components/case-studies/case-studies.scss";
import "../components/project-browser/project.scss";

export default class Dashboard extends Component {
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("dashboard");
  }
  render () {
    return (
      <div className="project surface">
        <div className="CaseStudy section">
          <BackButton />
          <h1>Dashboard</h1>
          <h2>Users wanted an at-a-glance view into the ad performance of their applications. I conducted a research study to determine what data users valued most, and then designed a dashboard feature to prominently visualize it. It became the most used screen within the platform.</h2>
          <div className="CaseStudy_specimen" style={{paddingTop: "65.839%" }}>
            <img className="CaseStudy_specimen__image" src="/projects/older/dashboard-full.png" alt="Smaato Dashboard" />
          </div>
        </div>
      </div>
    )
  }
}