import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from "../../components/case-studies/back-button/back-button.js";
import CaseStudyPersonas from './Personas';
import CaseStudyWorkflow from './Workflow';
import CaseStudyWireframe from './Wireframe';
import CaseStudyPrototype from './Prototype';
import CaseStudyUsabilityTesting from './UsabilityTesting';
import CaseStudyBuilding from './Building';
import CaseStudyBeta from './Beta';
import CaseStudyLaunch from './Launch';
import '../../components/case-studies/case-studies.scss';

class Cryptoberg extends React.Component {

  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("cryptoberg");
  }

  render() {
    return (
      <div className="project">
        <div className="Quote CaseStudy section">
          <BackButton />
          <h1>Cryptoberg</h1>
          <h2>In traditional financial markets, a broker-dealer chats with clients &amp; negotiates trades. Within the crypto space, the same functionality was desired but did not exist before Cryptoberg.</h2>
          <div className="CaseStudy_specimen" style={{paddingTop: "72.541%"}}>
            <img className="CaseStudy_specimen__image" src="/projects/cryptoberg/specimen.jpg" alt="Cryptoberg" />
          </div>
          {/* <div className="CaseStudy_device">
            <img className="CaseStudy_device_image" src="/projects/cryptoberg/preview-device.png" alt="Cryptoberg" />
          </div> */}
          <h4>The Process and Decisions I Made While Leading the Design Team for the Cryptoberg Project:</h4>
          {/* <ul className="CaseStudy_cloud">
            <li className="label">Methods Utilized:</li>
            <li>User Personas</li>
            <li>Workflow Paths</li>
            <li>Wireframes</li>
            <li>Prototyping</li>
            <li>Usability Testing</li>
            <li>Qualitative Analytics</li>
            <li>Diary Studies</li>
            <li>User Session Recording</li>
            <li>Beta Testing</li>
          </ul> */}
          <CaseStudyPersonas />
          <CaseStudyWorkflow />
          <CaseStudyWireframe/>
          <CaseStudyPrototype />
          <CaseStudyUsabilityTesting />
          <CaseStudyBuilding />
          <CaseStudyBeta />
          <CaseStudyLaunch/>
        </div>
      </div>
    )
  }
}

export default Cryptoberg;
