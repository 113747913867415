import React from 'react';
import './Building.scss';

class CaseStudyBuilding extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">6</span>
          Building It
        </h3>
        <p>This is where the "handoff" to engineering would typically take place, but I don’t prefer this approach and mindset.</p>
        <p>Instead of handing off our work to another team, <strong>I prefer to work directly with engineers and collaborate, solving problems and answering questions.</strong></p>
        <p>Since we built a high quality prototype with a visual design in React, we were able to refactor and use most of the code for production, which freed up the engineers to focus on the logic behind the application and not bother with building visual components and writing CSS.</p>
        <div className="CaseStudy__Building">
          <img className="CaseStudy__Building__image" src="/projects/cryptoberg/building-it.png" alt="Building It" />
        </div>
      </>
    )
  }
}

export default CaseStudyBuilding;
