import React from 'react';
import './Launch.scss';

class CaseStudyLaunch extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">8</span>
          Launch
        </h3>
        <p>
          After the beta, we were nearly ready to launch the product. We created documentation, how-to videos, and began training the sales &amp; support team. <strong>The project was rebranded and acquired by a leading brokerage firm. It is now used daily to execute trades.</strong>
        </p>
        <div className="CaseStudy_Launch_image">
          <img src="/projects/cryptoberg/launch.jpg" alt="Launch" />
        </div>
      </>
    )
  }
}

export default CaseStudyLaunch ;
