import React from 'react';
import './Prototypes.scss';
import { DefaultPlayer as Video } from 'react-html5video';

class Prototypes extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">4</span>Prototypes and Testing
        </h3>
        <p>
          With a workflow established and content organized into wireframes, it was time to build a prototype. This would enable us to gather feedback from users. This is critical, especially when you are designing an experience that can potentially cause user attrition.
        </p>
        <p>
          <strong>We built code prototypes that could run in a test user's mobile browser and continuously tested these with real users.</strong> We tracked the basics with analytics: clicks, event tracking with timers, session recordings, and heatmaps, organized by user segments that matched up with our user personas.
        </p>
        <p>
          We also employed qualitative research methods, asking users to track their feelings and overall impression in surveys. This allowed us to map out our users' journeys and iterate on the prototype until we felt it was dialed in.
        </p>
        <div className="CaseStudy_exhibitHeader">
          The persona of <strong>Kierra</strong> adding her vehicle to her policy quote with the prototype:
        </div>
        <span className="Quote__prototype Quote__prototype--kierra">
          <div className="Quote__prototype__card">
            <img className="Quote__prototype__card__image" src={"/projects/quote/persona-item-"+this.props.state.personas[1].items["vehicle"].id+".png"} alt={this.props.state.personas[1].items["vehicle"].itemTitle} />
            <span className="Quote__prototype__card__title">
              {this.props.state.personas[1].items["vehicle"].itemLabel}
            </span>
            <span className="Quote__prototype__card__item">
              {this.props.state.personas[1].items["vehicle"].itemTitleShort}
            </span>
            <span className="Quote__prototype__card__itemDetail">
              {this.props.state.personas[1].items["vehicle"].itemDetails}
            </span>
          </div>
          <span className="Quote__prototype__device">
            <Video muted loop playsInline="playsinline" poster="/projects/quote/quote-personas-kierra-poster.jpg"
                controls={['PlayPause', 'Seek']}>
                <source src="/projects/quote/quote-personas-kierra.mp4" type="video/mp4"/>
                <source src="/projects/quote/quote-personas-kierra.webm" type="video/webm"/>
                <source src="/projects/quote/quote-personas-kierra.ogv" type="video/ogg"/>
            </Video>
          </span>
        </span>
        <div className="CaseStudy_exhibitHeader">
          The persona of <strong>Jessica</strong> adding a citation to her driving information with the prototype:
        </div>
        <span className="Quote__prototype Quote__prototype--jessica">
          <div className="Quote__prototype__card">
            <img className="Quote__prototype__card__image" src={"/projects/quote/persona-item-"+this.props.state.personas[2].items["citation"].id+".png"} alt={this.props.state.personas[1].items["vehicle"].itemLabel} />
            <span className="Quote__prototype__card__title">
              {this.props.state.personas[2].items["citation"].itemLabel}
            </span>
            <span className="Quote__prototype__card__item">
              {this.props.state.personas[2].items["citation"].itemTitle}
            </span>
            <span className="Quote__prototype__card__itemDetail">
              {this.props.state.personas[2].items["citation"].itemDetails}
            </span>
          </div>
          <span className="Quote__prototype__device">
            <Video loop muted playsInline="playsinline" poster="/projects/quote/quote-personas-jessica.jpg"
                controls={['PlayPause', 'Seek']}>
                <source src="/projects/quote/quote-personas-jessica.mp4" type="video/mp4"/>
                <source src="/projects/quote/quote-personas-jessica.webm" type="video/webm"/>
                <source src="/projects/quote/quote-personas-jessica.ogv" type="video/ogg"/>
            </Video>
          </span>
        </span>
        <div className="CaseStudy_exhibitHeader">
          The persona of <strong>David</strong> adding his wife to his policy quote with the prototype:
        </div>
        <span className="Quote__prototype Quote__prototype--david">
          <div className="Quote__prototype__card">
            <img className="Quote__prototype__card__image Quote__prototype__card__image--circle" src={"/projects/quote/persona-item-"+this.props.state.personas[3].items["spouse"].id+".png"} alt={this.props.state.personas[1].items["vehicle"].itemLabel} />
            <span className="Quote__prototype__card__title">
              {this.props.state.personas[3].items["spouse"].itemLabel}
            </span>
            <span className="Quote__prototype__card__item">
              {this.props.state.personas[3].items["spouse"].itemTitle}
            </span>
            <span className="Quote__prototype__card__itemDetail">
              {this.props.state.personas[3].items["spouse"].itemDetails}
            </span>
          </div>
          <span className="Quote__prototype__device">
            <Video muted loop playsInline="playsInline" poster="/projects/quote/quote-personas-david.jpg"
                controls={['PlayPause', 'Seek']}>
                <source src="/projects/quote/quote-personas-david.mp4" type="video/mp4"/>
                <source src="/projects/quote/quote-personas-david.webm" type="video/webm"/>
                <source src="/projects/quote/quote-personas-david.ogv" type="video/ogg"/>
            </Video>
          </span>
        </span>
      </>
    )
  }
}

export default Prototypes;
