import React, { Component, useEffect } from 'react';
import { fb, firebaseAuth } from './config/constants';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import "./reset.scss";
import "./base.scss";
import "./loading.scss";
import ScrollToTop from "./ScrollToTop";
import ProjectBrowser from './components/project-browser/project-browser';
import Login from './Login';
import Home from './Home';
import Checkout from './protected/checkout';
import POS from './protected/pos';
import Refunds from './open/refunds/_Index.js';
import BLVD_UI from './open/blvd-ui';
import Quote from './open/quote/_Index.js';
import Cryptoberg from './open/cryptoberg/_Index.js';
import SmaatoUI from './open/smaato-ui/_Index.js';
import Settle from './open/settle.js';
import SPX from './open/spx.js';
import Dashboard from './open/dashboard';

function logout () {
  return firebaseAuth().signOut()
}

function login (email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw)
}

function PrivateRoute ({component: Component, authed, toggleProjectBrowser, setProject, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Login toggleProjectBrowser={toggleProjectBrowser} setProject={setProject} />}
    />
  )
}

function PublicRoute ({component: Component, authed, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  )
}



export default class App extends Component {

  state = {
    authed: false,
    loading: true,
    isLoading: true,
    content: false,
    showProjectBrowser: false,
    currentProject: null
  }

  toggleProjectBrowser = (toggle) => {
    this.setState({showProjectBrowser: toggle});
  }


  setProject = (id) => {
    if(this.state.currentProject!==id) this.setState({currentProject: id});
  }

  componentDidMount () {

    if(window.location.href.split('=')[1]) {
      login("user@calebplain.com", window.location.href.split('=')[1])
    }

    if(window.location.href.includes("logout")) {
      logout();
    }

    this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authed: true,
          uid: user.uid
        }, function() {
          fb.database().ref("users/"+user.uid)
          .once('value')
          .then(snapshot => {
            this.setState({content: snapshot.val(), loading: false});
          });
        })
      } else {
        this.setState({
          authed: false,
          loading: false,
          uuid: null
        })
      }
    });

    //this.setState({showProjectBrowser: false});
  }
  componentWillUnmount () {
    this.removeListener()
  }
  render() {
    return this.state.loading === true ? <div className="loading"><div class="spinner" /></div>: (
      <BrowserRouter>
        <ScrollToTop />
        {this.state.showProjectBrowser && <ProjectBrowser privateContent={this.state.content} currentProject={this.state.currentProject} authed={this.state.authed} /> }
        <Switch>

          <Route path='/' exact component={() => <Home privateContent={this.state.content} authed={this.state.authed} showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} />} />

          <PublicRoute authed={this.state.authed} path='/login' component={Login} showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} />
          <PublicRoute authed={this.state.authed} path='/refunds' component={() => <Refunds showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/quote' component={() => <Quote showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/blvd-ui' component={() => <BLVD_UI showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/cryptoberg' component={() => <Cryptoberg showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/smaato-ui' component={() => <SmaatoUI showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/settle' component={() => <Settle showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/spx' component={() => <SPX showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />
          <PublicRoute authed={this.state.authed} path='/dashboard' component={() => <Dashboard showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} />} />

          <PrivateRoute authed={this.state.authed} path='/checkout' setProject={this.setProject} toggleProjectBrowser={this.toggleProjectBrowser} component={() => <Checkout privateContent={this.state.content} showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} privateContent={this.state.content} />} />
          <PrivateRoute authed={this.state.authed} path='/pos' setProject={this.setProject} toggleProjectBrowser={this.toggleProjectBrowser} component={() => <POS privateContent={this.state.content} showProjectBrowser={this.state.showProjectBrowser} toggleProjectBrowser={this.toggleProjectBrowser} setProject={this.setProject} privateContent={this.state.content} />} />

          <Route render={() => <Redirect to='/'/> } />

        </Switch>
      </BrowserRouter>
    );
  }
}
