import React, { Component } from 'react';
import ProjectsGrid from './components/projects-grid/projects-grid';
import About from './components/about/about';

export default class Home extends Component {
  componentDidMount() {
    if(this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(false);
    }
  }
  render () {
    return (

      <div className="centerer about">
        <About />
        <ProjectsGrid privateContent={this.props.privateContent} authed={this.props.authed} />
      </div>
    )
  }
}