import React from 'react';
import './Wireframe.scss';

class CaseStudyWireframe extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">3</span>
          Wireframing
        </h3>
        <p>
          Now that we had a general idea of the screens within the application and the overall workflow, we needed to define the actual content that would appear on each screen.
        </p>
        <p>
          What information from the application did the user need to see, and what information did the application need to gather from the user?
        </p>
        <p>
          The best way to outline this conversation was with a wireframe. We created a wireframe for each screen, for everything from logging in to formulating a trade.
        </p>
        <div className="CaseStudy_Wireframe">
          <span className="CaseStudy_Wireframe_image">
            <img src="/projects/cryptoberg/wireframe-sketch.png" alt="Wireframe" />
          </span>
        </div>
      </>
    )
  }
}

export default CaseStudyWireframe;
