import React from 'react';
import BackButton from "../../components/case-studies/back-button/back-button.js";
import Magnifier from "react-magnifier";
import { Link } from 'react-router-dom';
import '../../components/case-studies/case-studies.scss';
import './_Index.scss';

class SmaatoUI extends React.Component {

  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("smaato-ui");
  }

  render() {
    return (
      <div className="project">
        <div className="SmaatoUIFramework CaseStudy section">
          <BackButton />
          <h1>Smaato UI Framework</h1>
          <h2>Smaato operates in a market that is constantly in flux and evolves with technology &amp; privacy trends. Such a disruptive market requires a UI system &amp; design process that can support rapid development of new products and features. This is the UI framework I created.</h2>
          <div className="CaseStudy_specimen" style={{paddingTop: "65.839%" }}>
            <img className="CaseStudy_specimen__image" src="/projects/smaato-ui-framework/screenshot-sdx.jpg" alt="Smaato SPX" />
          </div>
          <h4>Designing an Open Source UI Framework for Rapid UI Development:</h4>
          {/* <ul className="CaseStudy_cloud">
            <li className="label">Methods Utilized:</li>
            <li>Component Library</li>
            <li>Rapid Prototyping</li>
            <li>Heatmaps</li>
            <li>Journey Maps</li>
            <li>Usability Testing</li>
            <li>Qualitative Analytics</li>
            <li>Diary Studies</li>
            <li>User Session Recording</li>
            <li>Beta Testing</li>
          </ul> */}
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">1</span>Why We Built Our Own</h3>
          <p>
            As the Director of User Experience, I decided early on that Smaato required a UI framework. A UI framework is a catalog of reusable components and layouts that can be combined to quickly create an interface. The hard part was deciding whether we should work from existing frameworks like <a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">Bootstrap</a> and <a href="https://foundation.zurb.com/" target="_blank" rel="noopener noreferrer">Foundation</a>, or ambitiously create our own.
          </p>
          <p>This was a decision I took seriously. Commiting to an underlying technology or toolset (particularly with a UI baked in) can lead to serious consequences down the road. While getting started is easy, <strong>my experience has taught me that a design team will quickly outgrow the framework</strong>, and the process of modifying and creating new components based on the underlying framework can be time consuming and limiting.</p>
          <p>After reviewing the component needs from Smaato's existing platform and roadmap, it was clear that we needed to create our own. The components we required were complex and application specific. Piecing something together with a third party UI framework wasn't going to deliver the quality of experience we needed.</p>
          <img className="SmaatoUIFramework_box" src="/projects/smaato-ui-framework/box.png" alt="Smaato UI Framework" />
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">2</span>Starting the Process with a Specific Application in Mind</h3>
          <p>
            Instead of creating generic components isolated from a specific project, I decided that it made the most sense to design and build the UI framework in the context of developing an application. Smaato's product roadmap included a <a href="https://en.wikipedia.org/wiki/Demand-side_platform" target="_blank" rel="noopener noreferrer">Demand-side platform</a>, which was a massive undertaking and quite challenging from a UI perspective. It was the perfect candidate to <a href="https://en.wikipedia.org/wiki/Eating_your_own_dog_food" target="_blank" rel="noopener noreferrer">dog food</a> the UI framework. We called it the "SDX".
          </p>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">3</span>Building a Basic Framework</h3>
          <p>
            We started the process by determining the minimum viable version of the platform, and then split that into smaller projects. We discussed and estimated each project, identifying the phases of development and the components that would be necessary. We decided to use <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> as it was a perfect match for our component based approach.
          </p>
          <p>
            We identified that many of the necessary components were shared between layouts &amp; projects. Based on our findings, we created a prioritized wishlist of basic components and a few foundational layouts (vertical form, grid view, modal view, etc).
          </p>
          <p>We began designing the components in Sketch and created a GitHub repository to handle version control. It was important that every UI component lived in a Sketch file in addition to being a React component. Not all designers on the project were comfortable working with code, so this allowed for maximum flexibility.</p>
          <div className="SmaatoUIFramework_files">
            <img src="/projects/smaato-ui-framework/sketch-files.png" alt="Sketch Files" />
            <img src="/projects/smaato-ui-framework/react-components.png" alt="REACT Components" />
          </div>
          <p>
            Every component could be integrated into a compatible layout to form an integration, and we displayed all the possible configurations and states for each component.
          </p>
          <div className="SmaatoUIFramework_sketchfile">
            <img src="/projects/smaato-ui-framework/modal-sketch-file.png" alt="Modal Component in Sketch" />
          </div>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">4</span>Designing Advanced Components</h3>
          <p>
            Now that we had the basics, it was time to tackle advanced components. This included interactive charts, a date range selector, and data tables. These components were complex and required their own specific sub-components.
          </p>
          <p>
            Due to the complexity, we prototyped the advance components and conducted usability testing sessions. Our approach was qualitative and consisted of us assigning a user a task and <strong>documenting their understanding and experience in journey maps to identify problem spots.</strong>
          </p>
          <div className="SmaatoUIFramework_sketchfile">
            <img className="SmaatoUIFramework_sketchfile" src="/projects/smaato-ui-framework/calendar-sketch-file.png" alt="Modal Component in Sketch" />
          </div>
          <div className="SmaatoUIFramework_sketchfile">
            <img className="SmaatoUIFramework_sketchfile" src="/projects/smaato-ui-framework/linechart-sketch-file.png" alt="Modal Component in Sketch" />
          </div>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">5</span>Usability Testing</h3>
          <p>
            At this point, we had a solid minimal version of the SDX platform built with the framework. <strong>We felt confident in what we designed as we had researched our users' needs and conducted user testing throughout the process.</strong> It was now time to allow real users to use the app.
          </p>
          <p>
            We invited a few dozen users to try the app out, and gathered metrics quantifying what users were experiencing. We did this through heatmaps and session recordings. We also chose a subset of users for a diary study where they could track their experiences and respond to specific questions. This enabled us to further improve the experience in the SDX, which also filtered back and improved the usability of the UI framework.
          </p>
          <div className="SmaatoUIFramework_heatmap">
            <img src="/projects/smaato-ui-framework/heatmap.jpg" alt="Heatmap" />
          </div>
          <h3 className="CaseStudy_stepTitle">
            <span className="CaseStudy_stepTitle_number">6</span>Results</h3>
          <p>
            The SDX was successfully launched and was well received within the ad tech community. The Smaato UI framework was now a proven success, and we continued to use it for other projects at Smaato.
          </p>
          <p>
            <strong>The framework enabled both designers and engineers to speak a common language.</strong> High quality applications could be created quickly and efficiently. Since multiple projects were now built from a common UI codebase, bug fixes and improvements could benefit multiple applications.
          </p>
          <p>
            When a designer was assigned a new project, they were no longer starting from scratch, but instead could build an experience with components that both our users and engineers were familiar with.</p>
          <p>
            <strong>This completely reshaped our design process and allowed for rapid code prototypes (built with React) that were often able to serve as a first version, removing several steps from our existing product cycle.</strong>
          </p>
          <div className="CaseStudy_exhibitHeader">
            Examine the Components:
          </div>
          <div className="CaseStudy_magnifierContainer">
            <img className="noMagnifierOpt" src="/projects/smaato-ui-framework/ui.png" alt="UI Framework Components" />
            <Magnifier className="magnifierOpt" src={"/projects/smaato-ui-framework/ui.png"} mgWidth={500} mgShape={"square"} zoomFactor={.4} mgHeight={500} width="100%" />
          </div>

        </div>
      </div>
    )
  }
}

export default SmaatoUI;
