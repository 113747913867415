import React from 'react';
import './Beta.scss';

class CaseStudyBeta extends React.Component {
  render() {
    return (
      <>
        <h3 className="CaseStudy_stepTitle">
          <span className="CaseStudy_stepTitle_number">7</span>
          Beta Testing
        </h3>
        <div className="CaseStudy_Beta">
          <div className="CaseStudy_Beta_content">
            <p>
              With a usable build of the application, we launched a private beta with a small group of users.
            </p>
            <p>
              We used qualitative analytics to conduct further testing, which consisted of session recordings and diary studies. Users took shorthand notes about their experiences using the app (logging in, chatting, entering a trade, etc.), and filled out daily diary surveys.
            </p>
            <p>
            <strong>The beta was a success and proved to be very usable.</strong> From session recordings, we noticed some users were experiencing issues due to their device/window size, so we worked to make the app more responsive before the final build.
            </p>
          </div>
          <img className="CaseStudy_Beta_image" src="/projects/cryptoberg/beta-invite.png" alt="Beta Invite" />
        </div>
      </>
    )
  }
}

export default CaseStudyBeta;
