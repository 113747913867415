import React, { Component } from 'react'

export default class BLVD_UI extends Component {
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject("blvd-ui");
  }
  render () {
    return (
      <div>
        BLVD-UI
      </div>
    )
  }
}