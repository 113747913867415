import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firebaseAuth } from './config/constants';
import BackButton from "./components/case-studies/back-button/back-button.js";
import "./components/case-studies/case-studies.scss";
import "./components/project-browser/project.scss";

function login (email, pw) {
  return firebaseAuth().signInWithEmailAndPassword(email, pw)
}

function setErrorMsg(error) {
  return {
    loginMessage: error
  }
}

export default class Login extends Component {
  state = { loginMessage: null, loading: false }
  componentDidMount() {
    if(!this.props.showProjectBrowser) {
      this.props.toggleProjectBrowser(true);
    }
    this.props.setProject(null);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});
    login(this.email.value, this.pw.value)
      .catch((error) => {
          this.setState({loading: false});
          this.setState(setErrorMsg('Invalid username/password.'))
        })
  }
  render () {
    return (
      <div className="project login surface">
          <form onSubmit={this.handleSubmit}>
            <div className="form">
              <Link to="/" className="back-arrow">
                <svg width="32" height="32" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.625 12.75L6.375 8.5L10.625 4.25" stroke="#C3C7CF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Link>
              <div className={"fields " + (this.state.loading && "loading")}>
                <input className="form-control" type="hidden" ref={(email) => this.email = email} placeholder="Email" value="user@calebplain.com"/>
                <input type="password" autoFocus className="form-control" placeholder="Enter the password" ref={(pw) => this.pw = pw} />
                <button type="submit" className="btn btn-primary" title="Login">
                  <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12H19" stroke="#33343C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 5L19 12L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
            {this.state.loginMessage &&
              <div className="validation-message" role="alert">
                Sorry, that isn't the correct password.
              </div>
            }
          </form>
      </div>
    )
  }
}
